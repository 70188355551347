import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '38742314-c221-4285-af47-4efa82d94cf4',
    development: 'c5a84076-af99-4a3d-975e-1a919eb49829',
  },
}

export default {
  name: {
    nb: 'Salg og reiseliv',
    nn: 'Salg og reiseliv',
  },
  slug: 'sr',
  namespace: getDataByEnv('namespace', customDataByEnv),
  assets: {
    logo: '/themes/sr/images/logo.svg',
    favicon: '/themes/automatiseringsteknikk/favicon.ico',
  },
  styles: [
    '/themes/sr/css/sr.css',
    '/common/icons/icons.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til Salg- og reiseliv vg2</h1><p>Vi arbeider med å videreutvikle nettressursen. I løpet av skoleåret 2022–23 kommer det flere yrkesoppgaver, fagbegrep, multimedia og et dokumentasjonsverktøy.</p>',
    nn: '<h1>Velkommen til Salg- og reiseliv vg2</h1><p>Vi arbeider med å vidareutvikle nettressursen. I løpet av skuleåret 2022–23 kjem det fleire yrkesoppgåver, fagomgrep, multimedium og eit dokumentasjnsverktøy.</p>',
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Begreper',
        to: '/concepts/begreper',
      },
      {
        title: 'Fagartikler',
        to: '/articles/fagartikler',
      },
      {
        title: 'Nedlastbare filer',
        to: '/files/nedlastbare-filer',
      },
    ],
    nn: [
      {
        title: 'Fagomgrep',
        to: '/concepts/begreper',
      },
      {
        title: 'Fagartiklar',
        to: '/articles/fagartikler',
      },
      {
        title: 'Nedlastbare filer',
        to: '/files/nedlastbare-filer',
      },
    ],
  },
}
